<template>
  <div class="idle-banner">
    <Carousel
      ref="carousel"
      v-if="!loading && idle_banner.length"
      :per-page="1"
      :pagination-enabled="true"
      :navigation-enabled="true"
      :loop="true"
      :navigation-next-label="''"
      :navigation-prev-label="''"
      :pagination-color="'#F5B0B3'"
      :pagination-active-color="'#FFFFFF'"
      :autoplay="isAutoPlay && !autoplayPaused"
      :autoplay-timeout="autoplayTimeout"
      @pageChange="onSlideChange"
    >
      <slide v-for="(banner, index) in idle_banner" :key="banner._id">
        <div class="item-slide">
          <template v-if="checkBannerExt(banner.images) === 'mp4'">
            <video :ref="`video-${index}`" muted @click="goToHome" @ended="onVideoEnded">
              <source :src="getIdleBanner(banner.images)" type="video/mp4" />
            </video>
          </template>
          <template v-else>
            <img :src="getIdleBanner(banner.images)" :alt="banner.title" @click="goToHome" />
          </template>
        </div>
      </slide>
    </Carousel>
    <template v-else>
      <template v-if="loading">
        <div id="loaderspinwrap" class="loaderspinwrap" style="padding: 30px 0">
          <div class="loaderspin"></div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'IdleBanner',
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      defaultTimeout: 10000,
      currentTimeout: 10000,
      currentIndex: 0,
      autoplayPaused: false,
    };
  },
  computed: {
    idle_banner() {
      return this.$store.state.vmCola.cola_idle_screen;
    },
    isAutoPlay() {
      return this.idle_banner.length > 1;
    },
    loading() {
      return this.$store.state.vmCola.loading_banner;
    },
    autoplayTimeout() {
      return this.currentTimeout;
    },
  },
  methods: {
    checkBannerExt(images) {
      const arrImageUrl = (images?.length && images[0]?.url && images[0].url.split('/')) || [];
      const image = arrImageUrl.pop();
      const arrExt = image.split('.');
      const ext = arrExt.pop();
      return ext;
    },
    getIdleBanner(images) {
      if (images.findIndex((res) => res.url) > -1) {
        return images.find((res) => res.url).url;
      }
    },
    goToHome() {
      this.$router.push('/vmcola/home');
    },
    onSlideChange(newIndex) {
  this.currentIndex = newIndex;
  if (this.$refs.carousel) {
    const currentBanner = this.idle_banner[newIndex];
    if (currentBanner && this.checkBannerExt(currentBanner.images) === 'mp4') {
      const videoUrl = this.getIdleBanner(currentBanner.images);
      this.$store.dispatch('fetchCurrentVideoDuration', videoUrl)
        .then(() => {
          const videoDuration = this.$store.getters.getCurrentVideoDuration * 1000;
          this.currentTimeout = videoDuration;
          this.autoplayPaused = true;
          this.$nextTick(() => {
            const selector=`video-${newIndex}`
            const video = this.$refs[selector][0]
      if (video) {
        video.play();
            }
          });
        })
        .catch((error) => {
          console.error('Error fetching video duration:', error);
          this.currentTimeout = this.defaultTimeout;
          this.autoplayPaused = false;
        });
    } else {
      this.currentTimeout = this.defaultTimeout;
      this.autoplayPaused = false;
    }
  }
},
    onVideoEnded() {
      const nextIndex = (this.currentIndex + 1) % this.idle_banner.length;
      console.log(nextIndex)
      this.$refs.carousel.goToPage(nextIndex);
    }
  },
};
</script>
